import React from 'react'
import styled from 'styled-components';
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';
import { VideoFrame } from '../../components/VideoFrame';

import { salesLeaders } from '../../common/Util';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import IntegrationsGrid from '../images/integrationsGrid.svg'



const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | For Data Driven Sales Leaders</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='wave'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            What Truly Can Do For You
          </PageTitle2>
          <RoleSelector>
            <a href="/solutions/for-revenue-ops">
              <Role>Revenue Operations</Role>
            </a>
            <a href="/solutions/for-analytics-teams">
              <Role>Analytics Teams</Role>
            </a>
            <a href="/solutions/for-sales-enablement">
              <ActiveRole>Sales Enablement</ActiveRole>
            </a>
            <a href="/solutions/for-sales-leaders">
              <Role>Sales Leaders</Role>
            </a>
          </RoleSelector>

          <br/><br/>
          
          <Title3 as="h2" color={colors.accentFog} bold mt="3rem" mb="1.5rem">
              A Better Way To Measure Sales Process Adherence & Effectiveness
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
               You know that process is key to scaling sales success.  But until now, there was no way to analyze who is adhering to that process at scale, nor the impact that adherence has on conversion rates.
              <br/><br/><br/>
              <b><u>See Who Adheres To Your Process</u></b>. Are reps customizing their emails?  Are they leaving voicemails on missed calls?  Are they confirming meetings 24 hours before a held appointment?  Are they following the Sandler conversational methodology on calls?  Truly provides the analytics framework for answering questions like these and more.
              <br/><br/><br/><br/>
              <b><u>See Impact of Process On Sales Metrics</u></b>. Modeling sales KPIs and the funnel is a full time analyst job, and often takes weeks to clean, process and analyze the data.  Truly does all this math for you in Salesforce and gives you an easy way to see how adherence to any process impacts these KPIs.  This gives you unprecedented leverage in driving sales behavior change and process adoption.
              <br/><br/><br/>
               <b><u>Prove Sales Enablement ROI</u></b>.  No longer is the value of your function determined by anecdotal surveys run with the Sales Team.  Truly helps you quantify the value of any initiative in hard terms, making enablement a strategic function that the business invests in.
              <br/><br/><br/>
              </Large>

              <VideoFrameContainer>
                 <VideoFrame caption="Measure Process Adherence" background="Analytics" videoLink="https://player.vimeo.com/video/637984038?h=6244509ca9&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" />
              </VideoFrameContainer>
          
        </Box>
      </Section>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

const RoleSelector = styled.div`
  justify-content: center;
  border: white 2px solid;
  border-radius: 32pt;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-align: center;
  flex-grow: 0;
  width: 500px;
  margin: 0 auto;
`;

const VideoFrameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
`

const Role = styled.div`
  color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  border-radius: 32pt;
  display: flex;
  flex-align: center;
  flex-grow: 1;
  justify-content: center;

  :hover{
    background-color: rgba(255,255,255,0.3);
    animation: background color 0.2s;
  }
`;

const ActiveRole = styled.div`
  color: black;
  font-weight: bold;
  border-radius: 32pt;
  background-color: white;
  overflow: hidden;
  padding: 12px 16px;
  margin: 4px 4px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;